<template>
  <div>
    <section id="contentbox" class="container container-fluid">
      <div class="bg-dark text-white border border-secondary rounded px-3 py-2 mt-2">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-6">
            <div class="indexlogo">
              <!-- <div class="logoindex text-center">
                <a href="#"
                  ><img src="@/assets/wisdom/logo wisdombet-03.png" alt="WISDOMBET" title="WISDOMBET"
                /></a>
              </div> -->
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 text-center text-sm-center text-md-right">
            <h3 class="mt-1 font-weight-light">สมัครสมาชิก</h3>
          </div>
        </div>
      </div>
      <div class="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2 mb-5">
        <h5 class="text-primary text-center">
          <span class="badge badge-pill badge-primary font-weight-light">STEP 1</span> ยืนยันตัวตน
        </h5>
        <div class="col-12 col-sm-12 text-center pb-1 pt-2">
          <img :src="require('@/assets/menu_top/regis5.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="50%"/>
        </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
            <span class="flag-icon flag-icon-th mr-1"></span>
            <span class="pt-1 mr-2">+66</span>
          </div>
          <div class="col-9 col-sm-9 col-md-7 mb-2" id="username">
            <input
              type="mobile"
              name="mobile"
              pattern="\d*"
              class="form-control mobileinput"
              maxlength="10"
              required
              placeholder="ใส่เบอร์โทรศัพท์"
              v-model="mobile"
            />
          </div>
          <div class="col-3">
            <button class="btn brn-sm btn-primary" @click="sendSms"><small>ส่ง SMS</small></button>
          </div>
          <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
          </div>
          <div class="col-12 col-sm-12 col-md-9 text-right text-md-left">
            <small class="text-secondary">กรุณาใส่หมายเลขโทรศัพท์เพื่อยืนยันตัวตน</small>
          </div>
          <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
            <b>OTP ยืนยัน</b>
          </div>
          <div class="col-12 col-sm-12 col-md-9 mb-2">
            <input
              type="text"
              pattern="\d*"
              class="form-control"
              name="otpsms"
              maxlength="4"
              placeholder="กรอกหมายเลข OTP จาก SMS"
              v-model="smsotp"
              required
            />
            <span id="smsotp_text"></span>
          </div>
          <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
          </div>
          <div class="col-12 col-sm-12 col-md-9 text-right text-md-left" v-if="showGuideOTP">
            <small class="text-secondary">รหัส OTP สามารถใช้ได้เพียง 3 นาทีเท่านั้น !</small>
          </div>
        </div>
        <form class="form-login form-horizontal"
        id="register"
        :class="{ 'form-group--error': $v.$error }"
        @submit.prevent="onSubmit" >
          <input type="hidden" name="csrf_token" value="b6ad00a2081f2371a314244ac1f81519" />
          <input type="hidden" name="register3" value="1" />
            <div class="row">
              <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
                <b>ชื่อผู้ใช้งาน</b>
              </div>
              <div class="col-12 col-sm-12 col-md-9 mb-2" id="username">
                <input
                  type="text"
                  class="form-control"
                  name="username"
                  placeholder="username (0-9,a-z)"
                  v-model.trim="$v.username.$model"
                  required
                />
                <span id="username_text"></span>
              </div>
              <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
                <b>รหัสผ่าน</b>
              </div>
              <div class="col-12 col-sm-12 col-md-9 mb-2" id="password">
                <input
                  type="password"
                  class="form-control"
                  name="password"
                  id="password_val"
                  placeholder="password"
                  v-model.trim="$v.password.$model"
                  required
                />
                <span id="password_text"></span>
              </div>
              <div class="col-12 col-sm-12 col-md-2 mb-2 d-flex align-items-center">
                <b>ยืนยันรหัสผ่าน</b>
              </div>
              <div class="col-12 col-sm-12 col-md-9 mb-2" id="repassword">
                <input
                  type="password"
                  class="form-control"
                  name="repassword"
                  id="repassword_val"
                  placeholder="กรอก password อีกครั้ง"
                  v-model.trim="$v.password_confirmation.$model"
                  required
                />
                <span id="repassword_text"></span>
              </div>
            </div>
          <div class="bg-secondary border-dark shadow-sm p-3 mt-2 mb-0 fixed-bottom">
            <div class="row">
              <div class="col">
                <button type="submit" class="btn btn-primary btn-block" :disabled="submited">
                  ถัดไป <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { checkSpecialChar } from '@/utils/string'
import { required } from 'vuelidate/lib/validators'
export default {
  data () {
    return {
      mobile: '',
      smsotp: '',
      showGuideOTP: false,
      username: '',
      password: '',
      password_confirmation: '',
      submited: false
    }
  },
  created () {
    const afKey = this.$route.params.id ? this.$route.params.id : ''
    const agKey = this.$route.params.userAg ? this.$route.params.userAg : ''
    console.log('create regis')
    console.log(afKey)
    console.log(agKey)
    if (!this.isMember) {
      console.log('!this.isMember')
      this.setAfKey(afKey)
      this.setAgKey(agKey)
    }
    this.$router.replace('/')
  },
  computed: {
    ...mapState({
      isMember: (state) => state.authModule.isMember
    })
  },
  methods: {
    ...mapActions(['setAfKey', 'setAgKey', 'registerCreateUser', 'setLoading', 'openErrorPopup', 'openInfoPopup', 'loginAction', 'requestUsername', 'registerSendOTP']),
    async sendSms () {
      const isSucces = await this.registerSendOTP({
        mobile: this.mobile
      })
      if (isSucces) {
        this.showGuideOTP = true
        this.openInfoPopup('ระบบส่ง SMS ไปแล้ว เช็คข้อความบนมือถือของคุณ')
      }
    },
    async onSubmit () {
      this.setLoading(true)
      this.submited = true
      this.$v.$touch()
      if (this.$v.$pending || this.$v.$error) {
        window.scrollTo(0, 0)
        this.openErrorPopup('กรุณากรอกข้อมูลให้เรียบร้อย')
        this.setLoading(false)
      } else {
        const isSucces = await this.registerCreateUser({
          username: this.username,
          password: this.password,
          password_confirmation: this.password_confirmation,
          af: this.$route.params.id ? this.$route.params.id : '',
          ag: this.$route.params.userAg ? this.$route.params.userAg : '',
          mobile: this.mobile,
          otp: this.smsotp
        })

        if (isSucces) {
          const isSucces = await this.loginAction({
            username: this.username,
            password: this.password
          })
          if (isSucces) {
            this.setLoading(false)
            this.submited = false
            this.requestUsername()
            this.$router.replace('/member/settings/bank/add')
            this.$bvModal.show('modal-user-register_success')
          }
        } else {
          this.setLoading(false)
          this.submited = false
          return false
        }
      }
    }
  },
  validations () {
    return {
      username: {
        required,
        validateNo: username => {
          return checkSpecialChar(username)
        }
      },
      password: {
        required,
        validateNo: username => {
          return checkSpecialChar(username)
        }
      },
      password_confirmation: {
        required,
        validateNo: username => {
          return checkSpecialChar(username)
        }
      }
    }
  }
}
</script>

<style>
</style>
